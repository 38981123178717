<template>
  <div id="about">
    <div class="about">
      <div class="blur"><div></div></div>
      <TitlePage
        class="home-title"
        :showButton="false"
        :header="headerTitle"
        :description="description"
        :btnTxt="buttonTxt"
        :show="true"
      />
    </div>

    <div class="pysicians">
      <div>
        <h1>Our Physicians</h1>
        <p>Dedicated to Healing</p>
      </div>
    </div>

    <div class="aboutUs">
      <div class="DetailTable">
        <div class="image">
          <img :src="image1" alt="" />
        </div>
        <div class="details">
          <h4>Dr. Lusako Mwaikasu, MD</h4>
          <p>
            Dr. Lusako Mwaikasu, MD Obstetrician and Gynecologist Infertility
            and IVF Specialist, Advanced Ultrasound in Obstetrics and gynecology
            expert, FMF Certified Nuchal Translucency scan, Fistula Surgeon,
            Committed to the holistic patient-centered standard of care.
          </p>
        </div>
      </div>
      <div class="DetailTable">
        <div class="image">
          <img :src="image2" alt="" />
        </div>
        <div class="details">
          <h4>Dr. Glory Joseph, MD</h4>
          <p>
            A Pediatrician, Adult, and Paediatric Cardiologist Dr. Glory Joseph
            has been working with patients at the Bugando Medical Hospital and
            has committed her time to the family, helping treat women and
            children.
          </p>
        </div>
      </div>
    </div>
    <router-link to="/contact"
      ><button class="about-btn">Make An Appointment</button></router-link
    >
    <Footer />
  </div>
</template>

<script>
import TitlePage from "../components/layouts/TitlePage";
import Footer from "../components/Footer";
export default {
  name: "About",
  components: {
    TitlePage,
    Footer,
  },
  data() {
    return {
      headerTitle: "About Us",
      description:
        "Royal Hospitals was founded under the core principles of Love and Care. We aim to make the world a better place for one patient at a time. We combine the state of the art technology with a patient-centered design on both the services we provide and the facilities that we have.",
      buttonTxt: "Download Our Profile Document",
      image1: require("@/assets/aboutImages/royal hospitals indoor shoot-4.webp"),
      image2: require("@/assets/aboutImages/royal hospitals dr glory shoot-110.webp"),
    };
  },
};
</script>

<style scoped>
.blur {
  position: absolute;
  background-image: url("../assets/facilitiesImages/0499e6_35c9d98cc15142cf9c3594f291d4ca60_mv2.webp");
  background-position: center;
  background-size: cover;
  filter: blur(0px);
  -webkit-filter: blur(4px);
  width: 100%;
  height: 100%;
}
.about {
  contain: content;
}
.home-title {
  position: relative;
  height: 70vh;
  background-color: rgba(59, 77, 177, 0.5);
  display: grid;
  place-items: center;
}
.pysicians {
  background-color: #2e3c8b;
  color: #fff;
  height: 25vh;
  width: 100%;
  display: grid;
  place-items: center;
}

.aboutUs {
  display: flex;
  width: 80%;
  margin: auto;
  margin-top: 100px;
}
.DetailTable {
  width: 90%;
}
.DetailTable img {
  width: 90%;
}
.DetailTable h4 {
  color: #ce515c;
  padding-top: 40px;
  padding-bottom: 30px;
  text-align: left;
}
.details {
  width: 90%;
  margin: auto;
}
.details p {
  color: #3b4db1;
  line-height: 30px;
  text-align: left;
}
.about-btn {
  height: 40px;
  background-color: #ce515c;
  border: unset;
  color: #fff;
}
button {
  margin-top: 15%;
  margin-bottom: 5%;
  transition: 200ms;
  transition-timing-function: ease-in-out;
}
button:hover {
  opacity: 0.9;
}
@media screen and (max-width:720px){
  .DetailTable {
  width: 100%;
}
  .aboutUs {
  display: block;
}
.details p {
  text-align: center;
}
}
</style>